<template>
  <div class="ml-2 mt-2">
    <h3>Vizualizer Component</h3>
    <b-alert show variant="info">
      Please set your device volume to zero, before pressing start.
    </b-alert>
    
    <p>Microphone <span v-if="audiovizdirect && !audiovizdirect.isActive()">(Silent)</span></p>
    <audio class="inline-block" ref="audiodirect" playsinline/>
    <audioviz ref="audiovizdirect"/>
    
    <p>Peer <span v-if="audiovizpeer && !audiovizpeer.isActive()">(Silent)</span></p>
    <audio class="inline-block" ref="audiopeer" playsinline/>
    <audioviz ref="audiovizpeer"/>

    <button class="btn btn-primary" @click="createAudioStream()">Create Audio Stream</button>&nbsp;
    <button class="btn btn-secondary" @click="destroyAudioStream()">Destroy Audio Stream</button><br/>
    <br/>
    <button class="btn btn-primary" @click="createPeers()">Create Peers</button>&nbsp;
    <button class="btn btn-secondary" @click="destroyPeers()">Destroy Peers</button><br/>
    <br/>
    <div><input type="checkbox" id="autoRebind" v-model="autoRebind">&nbsp;<label for="autoRebind">Autofix Audio</label></div>
    <div v-if="neededRebind > 0">
      Needed rebind, count= {{ neededRebind }}
    </div>
  </div>
</template>

<script>
import audioviz from "@/components/audioviz.vue";
import { getLog } from "@/services/log";
import Peer from "simple-peer";
import { setInterval, clearInterval } from 'timers';
let log = getLog("sp");

export default {
  name: 'app',
  components: {
    audioviz,
  },
  data() {
    return {
      stream: null,
      peerstream: null,
      peer1: null,
      peer2: null,
      count: 0,

      autoRebind: false,
      neededRebind: 0,
      audiovizdirect: null,
      audiovizpeer: null,
    };
  },
  mounted() {
    this.audiovizdirect = this.$refs.audiovizdirect;
    this.audiovizpeer = this.$refs.audiovizpeer;
  },
  beforeDestroy() {
    this.destroyPeers();
    this.destroyAudioStream();
  },
  methods: {
    createAudioStream() {
      log.log("createAudioStream");
      let that = this;
      navigator.mediaDevices.getUserMedia({audio:true, video:false}).then((stream) => {
        log.log("getUserMedia got", stream);
        that.stream = stream;
        that.$refs.audiovizdirect.createSpectrum(stream);
        if (that.peer1) {
          log.log("adding stream to peer1");
          that.peer1.addStream(stream);
        }
      });
    },
    destroyAudioStream() {
      log.log("destroyAudioStream");
      if (this.stream) {
        this.stream.getTracks().forEach(track => track.stop());
        this.stream = null;
      }
      this.$refs.audiovizdirect.stop();
    },
    createPeers() {
      log.log(`createPeers ${this.count}`);
      this.count += 1;
      this.peer1 = new Peer({ initiator: true, stream:this.stream })
      this.peer2 = new Peer()

      let that = this;
      this.peer1.on('signal', data => {
        // when peer1 has signaling data, give it to peer2 somehow
        that.peer2.signal(data)
      })

      this.peer1.on('data', data => {
        log.log('got a message from peer2: ' + data);
      });

      this.peer2.on('signal', data => {
        // when peer2 has signaling data, give it to peer1 somehow
        that.peer1.signal(data)
      })

      this.peer1.on('connect', () => {
        // wait for 'connect' event before using the data channel
        that.peer1.send('hey peer2, how is it going?');
        //if (that.stream)
        // that.peer1.addStream(that.stream);
      })

      this.peer2.on('data', data => {
        // got a data channel message
        log.log('got a message from peer1: ' + data)
        that.peer2.send('hey peer1, doing well!');
      })

      this.peer2.on('stream', stream => {
        log.log("got a stream", stream);
        that.peerstream = stream;
        that.$refs.audiopeer.srcObject = stream;
        that.$refs.audiopeer.play();
        that.$refs.audiovizpeer.createSpectrum(stream);
      })

      this.peer2.on('track', (track, stream) => {
        log.log("got a track", track, stream);
      });

      if (this.checkPeeringHandler) {
        clearInterval(this.checkPeeringHandler);
      }
      this.checkPeeringHandler = setInterval(() => { that.checkPeeringStream(); }, 1000);
    },
    forceCrash() {
      this.peer1.removeStream(this.stream);
    },
    describe(stream) {
      if (stream)
        return {
          id: stream.id,
          active: stream.active,
          tracks: stream.getAudioTracks()
        };
    },
    checkPeeringStream(force) {
      if (!this.autoRebind) {
        log.log("skip checkPeeringStream");
        return;
      }
      log.log("checkPeeringStream", this.describe(this.stream), this.describe(this.peerstream));
      if (force || (this.stream
        && this.peerstream
        && this.audiovizdirect.isActive()
        && !this.audiovizpeer.isActive())) {
        log.log("***** Stream not working, try to rebind *****");
        this.neededRebind += 1;
        try {
          this.destroyPeers(true);
          this.createPeers();
        } catch(e) {
          log.log("error", e);
        }
      }
    },
    destroyPeers(keepPeer2Alive) {
      log.log("destroyPeers")
      if (this.checkPeeringHandler) {
        clearInterval(this.checkPeeringHandler);
        this.checkPeeringHandler = null;
      }

      if (this.peer1)
        this.peer1.destroy();
      // Need to keep peer2 alive or it does not work around the issue.
      if (!keepPeer2Alive && this.peer2)
        this.peer2.destroy();

      if (this.peerstream) {
        this.peerstream.getTracks().forEach(track => track.stop());
        this.peerstream = null;
      }

      this.$refs.audiopeer.srcObject = null;
      this.$refs.audiovizpeer.stop();
    },
  }
}
</script>

<style scoped>

.inline-block {
  display: inline-block;
}

.viz-wrapper {
  position: relative;
  z-index: 0;
  overflow: hidden;
  height: 50px;
  width: 50px;
}

.viz-over {
  background-color: rgb(130, 130, 248);
  z-index: -1;
}

.viz-circle {
  position: absolute;
  display: inline-block;
  height: 50px;
  width: 50px;
  background-color: rgba(130, 130, 248, 0.5);
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  color: white;
  font-weight: bold;
  padding-top: 14px;
}

</style>