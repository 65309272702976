<template>
  <div class="viz-wrapper viz-under"> 
    <div class="viz-circle" :style="`transform: scale(${instantMeter * 2});`"></div>
    <div class="viz-circle viz-over" :style="`transform: scale(${slowMeter * 2});`"></div>
  </div>
</template>

<script>
import { createSoundMeter } from "@/services/mediautils";
import { getLog } from "../services/log";
let log = getLog("av", false);

export default {
  props: {
    stream: null,
  },
  data() {
    return {
      release: null,
      audioStream: null,
      instantMeter: 0,
      slowMeter: 0,
      clipMeter: 0,
    };
  },
  watch: {
    stream() {
      this.createSpectrum(this.stream);
    }
  },
  mounted() {
    this.createSpectrum(this.stream);
  },
  beforeDestroy() {
    this.stop();
  },
  methods: {
    async createSpectrum(stream) {
      log.log("createSpectrum", stream);
      if (this.audioStream == stream)
        return;
      this.audioStream = stream;
      if (!stream) {
        this.stop();
        return;
      }
      this.release = await createSoundMeter(stream, (event) => {
        if (event.data && this.audioStream) {
          this.instantMeter = event.data.instant.toFixed(3);
          this.slowMeter = event.data.slow.toFixed(3);
          this.clipMeter = event.data.clip;
        }
      });
    },
    isActive() {
      return this.instantMeter >= 0.0001
        || this.slowMeter >= 0.0001
        || this.clipMeter >= 0.0001;
    },
    stop() {
      log.log("stop");
      if (this.release) {
        this.release();
        this.release = null;
      }
      this.instantMeter = 0;
      this.slowMeter = 0;
      this.clipMeter = 0;
    }
  }
}
</script>

<style scoped>

.viz-wrapper {
  position: relative;
  z-index: 0;
  overflow: hidden;
  height: 50px;
  width: 50px;
}

.viz-under {
  background-color: rgb(37, 37, 100);
  border-radius: 50%;
}

.viz-over {
  background-color: rgb(190, 190, 250);
  z-index: -1;
}

.viz-circle {
  position: absolute;
  display: inline-block;
  height: 50px;
  width: 50px;
  background-color: rgba(195, 195, 255, 0.5);
  border-radius: 50%;
  text-align: center;
  color: white;
  font-weight: bold;
  padding-top: 14px;
}

</style>